import React, { useContext } from "react";
import appContext from "../../../context/appContext";
import mapTabContext from "../../../context/mapTabContext";
import { Box, RadioButtonGroup, Text } from "grommet";
import ReactGA from "react-ga";
ReactGA.initialize("UA-3386980-1");

const CriteriaSetter = () => {
    const { selectedLocationMetadata } = useContext(appContext);
    const { selectedCriteria, setEquityCriteria, isFilteringDisabled } = useContext(mapTabContext);
    if (isFilteringDisabled) return null;
    return (
        <Box direction="row" gap="small" margin={{ right: "small" }}>
            <Text size="xsmall" weight="bold" alignSelf="center" border={{ size: "medium", style: "solid", side: "bottom", color: "heRed" }}>
                Set criteria:
            </Text>
            <RadioButtonGroup
                name='criteriaType'
                options={[
                    { value: "None", label: "None" },
                    { value: "Median", label: (selectedLocationMetadata.geo_level === "county" ? "County" : selectedLocationMetadata.geo_level === "state" ? "State" : "Community") + " Median" },
                    { value: "U.S. Average", label: "U.S. Average" },
                ]}
                direction="row"
                gap="none"
                value={selectedCriteria}
                onChange={(event) => {
                    setEquityCriteria(event.target.value);
                    if (window.location.hostname === "headwaterseconomics.org") {
                        ReactGA.event({
                            category: "User",
                            action: "Changed Comparison Criteria",
                            label: event.target.value,
                        });
                    }
                }}
            >
                {(option, { checked, hover }) => {
                    const background = "none";
                    const borderColor = checked ? "text" : "rgba(0,0,0,0)";
                    return (
                        <Box
                            background={background}
                            border={[
                                { side: "top", color: "rgba(0,0,0,0)", size: "medium", style: "solid" },
                                { side: "bottom", color: borderColor, size: "medium", style: "solid" },
                            ]}
                            pad={{ vertical: "none", horizontal: "xxsmall" }}
                        >
                            <Text size="xsmall">{option.label}</Text>
                        </Box>
                    );
                }}
            </RadioButtonGroup>
        </Box>
    );
}

export default CriteriaSetter;
