import React, { useReducer, useEffect } from "react";
import AppContext from "./appContext";
import { useGeoMetadata } from "@headwaters-economics/web-shared";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const initialState = {
    selectedLocation: null,
    selectedLocationId: null,
    selectedLocationMetadata: null,
    appTab: null,
    appErrorMessage: null,
    searchCache: {},
    isClimateWarningAvailableForLocation: true
};

const AppProvider = (props) => {
    const navigate = useNavigate();
    let location = useLocation();
    let { placeId } = useParams();

    useEffect(() => {
        const locParts = location.pathname.split("/");
        if (locParts[2] === "explore" && (locParts[3] === "map" || locParts[3] === "climate")) {
            setLocation({ id: locParts[1] }, locParts[3]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeId]);

    const [state, dispatch] = useReducer(appReducer, initialState);
    const { geoMetadata } = useGeoMetadata({ geoIDs: state.selectedLocation ? [state.selectedLocation.id] : null });

    const setLocation = (place, tab) => {
        navigate("/" + place.id + "/explore/" + tab);
        dispatch({ type: "SET_PLACE", to: place });
    };

    const setAppTab = (index) => {
        const appTab = index === 0 ? "map" : "climate";
        if (state.selectedLocation){
           navigate("/" + state.selectedLocation.id + "/explore/" + (index === 0 ? "map" : "climate")); 
        }
        dispatch({ type: "SET_APP_TAB", to: appTab });
    };

    const setIsClimateWarningAvailableForLocation = (newValue) => {
        dispatch({ type: "SET_IS_CLIMATE_WARNING_AVAILABLE_FOR_LOCATION", to: newValue });
    }

    const throwAppError = (msg) => {
        dispatch({ type: "THROW_APP_ERROR", msg: msg });
    };

    return (
        <AppContext.Provider
            value={{
                selectedLocation: state.selectedLocation,
                selectedLocationId: state.selectedLocationId,
                appErrorMessage: state.appErrorMessage,
                isClimateWarningAvailableForLocation: state.isClimateWarningAvailableForLocation,
                setIsClimateWarningAvailableForLocation,
                selectedLocationMetadata: state.selectedLocation ? geoMetadata[state.selectedLocation.id] : null,
                searchCache: state.searchCache,
                appTab: state.appTab,
                setAppTab,
                setLocation,
                throwAppError,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

const appReducer = (state, action) => {
    switch (action.type) {
        case "SET_APP_TAB":
            return {
                ...state,
                appTab: action.to,
                isClimateWarningAvailableForLocation:true
            };
        case "SET_PLACE":
            return {
                ...state,
                selectedLocation: action.to,
                selectedLocationId: action.to.id,
                isClimateWarningAvailableForLocation:true
            };
        case "SET_IS_CLIMATE_WARNING_AVAILABLE_FOR_LOCATION":
            return {
                ...state,
                isClimateWarningAvailableForLocation:action.to
            }
        case "THROW_APP_ERROR":
            return {
                ...state,
                appErrorMessage: action.msg,
            };
        default:
            throw new Error();
    }
};

export default AppProvider;
