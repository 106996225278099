import React, { useMemo } from "react";
import { Box } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

function Loader({background}) {

    const Spinner = useMemo(() => (
        <Box background={background || 'lightgrey'} fill justify="center" align="center">
            <FontAwesomeIcon icon={faSpinner} spin size="6x" />
        </Box>
    ),[background]);

    return Spinner;

}


export default Loader;
