import React, { useReducer, useEffect, useContext, useState } from "react";
import appContext from "./appContext";
import MapTabContext from "./mapTabContext";
import {
  useEpsData,
  useEpsRanks,
  useExtentOfGeos,
  useFemaFloodData,
  useFirstStreetFloodData,
  useWildfireRiskData,
  useGeosIntersectingGeo,
  useNlcdData,
  useGeoMetadata,
} from "@headwaters-economics/web-shared";

import _debounce from "lodash/debounce";
import _merge from "lodash/merge";
import _cloneDeep from "lodash/cloneDeep";
import _pickBy from "lodash/pickBy";

const updateFilterDebounce = _debounce(function (val, dispatch, parameterKey) {
  dispatch({
    type: "SET_FILTER_VALUE",
    paramKey: parameterKey,
    to: val,
  });
}, 100);

const initialState = {
  tractLevelData: null,
  tracts: [],
  tractIDs: [],
  filters: [],
  sliderValues: {},
  selectedCriteria: "Median",
  nationalEpsData: null,
  // isMapLoaderVisible: true,
  downloadModalProps: null,
  isFilteringDisabled: false,
};

const MapTabProvider = (props) => {
  const { selectedLocationId } = useContext(appContext);
  const [state, dispatch] = useReducer(mapTabReducer, initialState);
  const { geosIntersectingGeo: tractsIntersectingSelectedLocation } =
    useGeosIntersectingGeo({
      inGeoID: selectedLocationId,
      outGeoType: "he-tract",
    });
  const { epsRanks } = useEpsRanks({ geoID: selectedLocationId });
  const { extentOfGeos: selectedLocationExtent } = useExtentOfGeos({
    geoIDs: state.tractIDs,
  });

  const [epsDataFetchParams, set_epsDataFetchParams] = useState({
    geoIDs: null,
    vars: null,
  });
  const { epsData, status: epsDataStatus } = useEpsData(epsDataFetchParams);
  const { femaFloodData, status: femaFloodDataStatus } = useFemaFloodData({
    geoIDs: state.tractIDs,
  });
  const { firstStreetFloodData, status: firstStreetFloodDataStatus } =
    useFirstStreetFloodData({ geoIDs: state.tractIDs });
  const { wildfireRiskData, status: wildfireRiskDataStatus } =
    useWildfireRiskData({ geoIDs: state.tractIDs });
  const { nlcdData, status: nlcdDataStatus } = useNlcdData({
    geoIDs: state.tractIDs,
  });
  const { geoMetadata, status: geoMetadataStatus } = useGeoMetadata({
    geoIDs: state.tractIDs,
  });

  useEffect(() => {
    const tractsWithSignificantOverlap =
      tractsIntersectingSelectedLocation.filter(
        (t) => (t.prop !== 0 && !t.prop) || t.prop > 0.01
      );
    const includedTracts =
      tractsWithSignificantOverlap.length > 0
        ? tractsWithSignificantOverlap
        : tractsIntersectingSelectedLocation;
    dispatch({
      type: "SET_TRACT_IDS",
      to: includedTracts.map((tract) => tract.id),
    });
  }, [tractsIntersectingSelectedLocation]);

  useEffect(() => {
    dispatch({ type: "RESET_STATE" });
  }, [selectedLocationId]);

  useEffect(() => {
    if (state.tractIDs.length > 0 && state.filters.length > 0) {
      set_epsDataFetchParams({
        geoIDs: ["0", ...state.tractIDs],
        vars: [
          "population",
          ...state.filters
            .filter(({ visible, type }) => visible && type === "equity")
            .map(({ key }) => key),
        ],
      });
    } else {
      set_epsDataFetchParams({ geoIDs: null, vars: null });
    }
  }, [state.tractIDs, state.filters]);

  useEffect(() => {
    if (epsRanks && epsRanks.length > 0) {
      const equityFilters = epsRanks.map((rank, i) => {
        return {
          key: rank.var,
          type: "equity",
          visible: i < 3,
          initialized: false,
        };
      });
      const exposureFilters = [
        "homes_exposed",
        "flood_factor",
        "floodplain_500",
        "not_canopy",
        "impervious",
      ].map((key) => {
        return {
          key: key,
          type: "exposure",
          visible: true,
          initialized: false,
        };
      });
      const allFilters = [...equityFilters, ...exposureFilters];

      dispatch({ type: "SET_FILTERS", to: allFilters });
    }
  }, [epsRanks]);

  useEffect(() => {
    if (
      epsDataStatus === "fetched" &&
      femaFloodDataStatus === "fetched" &&
      firstStreetFloodDataStatus === "fetched" &&
      wildfireRiskDataStatus === "fetched" &&
      nlcdDataStatus === "fetched" &&
      geoMetadataStatus === "fetched"
    ) {
      const { 0: nationalEpsData, ...tractLevelEpsData } = epsData;
      const allTractLevelData = _merge(
        {},
        tractLevelEpsData,
        femaFloodData,
        firstStreetFloodData,
        wildfireRiskData,
        nlcdData,
        geoMetadata
      );
      const completeTractLevelData = _pickBy(allTractLevelData, (item) =>
        item.population ? true : false
      );

      dispatch({ type: "SET_TRACT_LEVEL_DATA", to: completeTractLevelData });
      dispatch({
        type: "SET_NATIONAL_EPS_DATA",
        to: _merge({}, nationalEpsData),
      });
    }
  }, [
    epsDataStatus,
    femaFloodDataStatus,
    firstStreetFloodDataStatus,
    wildfireRiskDataStatus,
    nlcdDataStatus,
    geoMetadataStatus,
    epsData,
    femaFloodData,
    firstStreetFloodData,
    wildfireRiskData,
    nlcdData,
    geoMetadata,
  ]);

  useEffect(() => {
    if (state.tractLevelData) {
      let tracts;
      if (state.tracts.length === 0) {
        tracts = Object.entries(state.tractLevelData).map((entry) => {
          return {
            id: entry[0],
            include: true,
            data: entry[1],
          };
        });
      } else {
        tracts = state.tracts.map((t) => {
          return {
            id: t.id,
            include: t.include,
            data: state.tractLevelData[t.id],
          };
        });
      }

      dispatch({
        type: "SET_TRACTS",
        to: tracts,
        isFilteringDisabled: tracts.length === 1,
      });

      // initialize filter
      const filters = _cloneDeep(state.filters);
      filters.forEach((f) => {
        if (state.tracts && f.visible && !f.initialized) {
          const tractValues = tracts
            .map((t) => t.data[f.key].pct)
            .filter((t) => typeof t !== "undefined");

          // Set the slider range values, accounting for locations without data for a given filter
          const median = tractValues.length === 0 ? 0 : getMedian(tractValues);
          const min = tractValues.length === 0 ? 0 : Math.min(...tractValues);
          const max = tractValues.length === 0 ? 1 : Math.max(...tractValues);

          const sliderMin = Math.floor(min);
          const sliderMax =
            Math.ceil(max) + (Math.ceil(max) === sliderMin ? 1 : 0);
          const initialValue =
            state.selectedCriteria === "Median" && f.type === "equity"
              ? median
              : sliderMin;
          const range = sliderMax - sliderMin;
          const step = range / 100;
          f.sliderValue = initialValue;
          f.filterValue = initialValue;
          f.applied = initialValue > min;
          f.median = median;
          f.min = min;
          f.max = max;
          f.sliderMin = sliderMin;
          f.sliderMax = sliderMax;
          f.range = range;
          f.step = step;
          f.binCnt =
            tracts.length < 100
              ? 10
              : tracts.length > 1000
              ? 100
              : parseInt(tracts.length / 10, 10);
          f.initialized = true;
          f.incomplete = tractValues.length !== tracts.length;
          // const isMissingDataValues = tractValues.includes(undefined)
          // if (isMissingDataValues) f.visible = false;
        }
      });
      dispatch({ type: "SET_FILTERS", to: filters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tractLevelData]);

  useEffect(() => {
    const tracts = state.tracts.map((t) => {
      let include = true;
      state.filters
        .filter((f) => f.visible && f.initialized)
        .forEach((f) => {
          if (
            t.id in state.tractLevelData &&
            f.key in state.tractLevelData[t.id]
          ) {
            const tractVal = state.tractLevelData[t.id][f.key].pct;
            const filterVal = f.filterValue;
            const missingVal = !tractVal;
            if (tractVal < filterVal || (filterVal > 0 && missingVal)) {
              include = false;
            }
          }
        });
      return {
        id: t.id,
        include: include,
      };
    });
    dispatch({
      type: "SET_TRACTS",
      to: tracts,
      isFilteringDisabled: state.isFilteringDisabled,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filters]);

  useEffect(() => {
    if (state.isFilteringDisabled) {
      setEquityCriteria("None");
      setExposureCriteria("None");
    }
  }, [state.isFilteringDisabled]);

  const setDownloadModalProps = (props) => {
    dispatch({ type: "SET_DOWNLOAD_MODAL_PROPS", to: props });
  };

  const setEquityCriteria = (newCriteria) => {
    dispatch({ type: "SET_EQUITY_FILTERS", to: newCriteria });
  };
  const setExposureCriteria = (newCriteria) => {
    dispatch({ type: "SET_EXPOSURE_FILTERS", to: newCriteria });
  };

  const setFilterValueDebounce = (parameterKey, val) => {
    updateFilterDebounce(val, dispatch, parameterKey);
  };
  const setFilterValue = (parameterKey, val) => {
    dispatch({
      type: "SET_FILTER_VALUE",
      paramKey: parameterKey,
      to: val,
    });
  };
  const setSliderValue = (parameterKey, val) => {
    dispatch({
      type: "SET_SLIDER_VALUE",
      paramKey: parameterKey,
      to: val,
    });
  };
  const setFilters = (params) => {
    dispatch({ type: "SET_FILTERS", to: params });
  };

  // const setIsMapLoaderVisible = (visible) =>
  //   dispatch({ type: "SET_IS_MAP_LOADER_VISIBLE", to: visible });

  return (
    <MapTabContext.Provider
      value={{
        selectedLocationExtent: selectedLocationExtent,
        epsRanks: epsRanks,
        tractLevelData: state.tractLevelData,
        sliderValues: state.sliderValues,
        tracts: state.tracts,
        filters: state.filters,
        setFilters,
        selectedCriteria: state.selectedCriteria,
        // isMapLoaderVisible: state.isMapLoaderVisible,
        downloadModalProps: state.downloadModalProps,
        isFilteringDisabled: state.isFilteringDisabled,
        setFilterValueDebounce,
        setFilterValue,
        setSliderValue,
        setEquityCriteria,
        setExposureCriteria,
        // setIsMapLoaderVisible,
        nationalEpsData: state.nationalEpsData,
        setDownloadModalProps,
      }}
    >
      {props.children}
    </MapTabContext.Provider>
  );
};

const getMedian = (arr) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

const mapTabReducer = (state, action) => {
  let newState, filters;
  switch (action.type) {
    case "SET_EQUITY_FILTERS":
      newState = { selectedCriteria: action.to };
      switch (action.to) {
        case null:
          newState.selectedCriteria = null;
          break;
        case "None":
          filters = _cloneDeep(state.filters);
          newState.filters = filters.map((filter) => {
            if (filter.type === "equity") {
              filter.filterValue = filter.sliderMin;
              filter.sliderValue = filter.sliderMin;
              filter.applied = false;
            }
            return filter;
          });
          break;
        case "Median":
          filters = _cloneDeep(state.filters);
          newState.filters = filters.map((filter) => {
            if (filter.type === "equity") {
              filter.filterValue = filter.median;
              filter.sliderValue = filter.median;
              filter.applied = true;
            }
            return filter;
          });
          break;
        case "U.S. Average":
          filters = _cloneDeep(state.filters);
          newState.filters = filters.map((filter) => {
            if (filter.type === "equity" && filter.visible) {
              filter.filterValue = state.nationalEpsData[filter.key].pct;
              filter.sliderValue = state.nationalEpsData[filter.key].pct;
              filter.applied = true;
            }
            return filter;
          });
          break;
        default:
          break;
      }

      return {
        ...state,
        ...newState,
      };

    case "SET_EXPOSURE_FILTERS":
      newState = {};
      switch (action.to) {
        case "None":
          filters = _cloneDeep(state.filters);
          newState.filters = filters.map((filter) => {
            if (filter.type === "exposure") {
              filter.filterValue = filter.sliderMin;
              filter.sliderValue = filter.sliderMin;
              filter.applied = false;
            }
            return filter;
          });
          break;
        default:
          break;
      }

      return {
        ...state,
        ...newState,
      };

    case "SET_FILTER_VALUE":
      filters = _cloneDeep(state.filters);
      filters.find(({ key }) => key === action.paramKey).filterValue =
        action.to;

      //set the applied status
      filters
        .filter(({ visible }) => visible)
        .forEach((filter) => {
          const filterVal = filter.filterValue;
          let applied = false;
          state.tracts.forEach(({ id }) => {
            const tractVal = state.tractLevelData[id][filter.key].pct;
            if (tractVal < filterVal) {
              applied = true;
            }
          });
          filter.applied = applied;
        });

      return {
        ...state,
        filters: filters,
      };
    case "SET_SLIDER_VALUE":
      filters = _cloneDeep(state.filters);
      filters.find(({ key }) => key === action.paramKey).sliderValue =
        action.to;
      return {
        ...state,
        filters: filters,
      };
    case "SET_FILTERS":
      return {
        ...state,
        filters: action.to,
      };
    case "SET_TRACTS":
      return {
        ...state,
        tracts: action.to,
        isFilteringDisabled: action.isFilteringDisabled,
      };
    // case "SET_IS_MAP_LOADER_VISIBLE":
    //   return {
    //     ...state,
    //     isMapLoaderVisible: action.to,
    //   };
    case "SET_DOWNLOAD_MODAL_PROPS":
      return {
        ...state,
        downloadModalProps: action.to,
      };
    case "SET_TRACT_LEVEL_DATA":
      return {
        ...state,
        tractLevelData: action.to,
      };
    case "SET_NATIONAL_EPS_DATA":
      return {
        ...state,
        nationalEpsData: action.to,
      };
    case "RESET_STATE":
      return {
        ...initialState,
        // isMapLoaderVisible: true
      };
    case "SET_TRACT_IDS":
      return {
        ...state,
        tractIDs: action.to,
      };
    default:
      throw new Error();
  }
};

export default MapTabProvider;
