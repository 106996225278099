const appColors = {
    brand: "#0D4D80",
    included: "rgba(61,113,153,0.45)",
    included_border:"rgb(61, 113, 153)",
    excluded: "rgba(150,150,150,0.2)",
    excluded_border: "rgb(150,150,150)",
    hover: "#0D4D80",
    hover_pinned: "rgba(0, 255, 255,0.25)"

};
export default appColors;