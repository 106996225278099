import React from "react";
import { Box, Button, DataTable, Grid, Heading, Text } from "grommet";
import { Close as CloseIcon } from "grommet-icons";
import { useViewport, getRelativeBodySize } from "@headwaters-economics/web-shared";

const decimalFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 1,
});
const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 1,
});

const HeDataTable = ({ header, rows, onCloseButtonClick }) => {
  const { screenSize } = useViewport();
  const data = rows
    .map((e) => {
      const cnt = isNaN(e.value) ? null : decimalFormatter.format(e.value);
      const cnt_moe = isNaN(e.moe) ? null : decimalFormatter.format(e.moe);
      const pct =
        isNaN(e.pct) && e.key === "population"
          ? null
          : isNaN(e.pct)
          ? "no data"
          : percentFormatter.format(e.pct);
      const pct_moe = isNaN(e.pct_moe)
        ? null
        : percentFormatter.format(e.pct_moe);
      return {
        label: e.label,
        count: cnt ? (
          <Text size={getRelativeBodySize("xsmall", screenSize)}>
            <Grid fill columns={["3fr", "1fr", "3fr"]}>
              <Box fill>
                <Text
                  size={getRelativeBodySize("xsmall", screenSize)}
                  alignSelf="end"
                >
                  {cnt}
                </Text>
              </Box>
              <Box fill>
                <Text
                  size={getRelativeBodySize("xxsmall", screenSize)}
                  color="dark-5"
                >
                  {cnt_moe ? <>&nbsp;&plusmn;&nbsp;</> : null}
                </Text>
              </Box>
              <Box fill>
                <Text
                  alignSelf="start"
                  size={getRelativeBodySize("xxsmall", screenSize)}
                  color="dark-5"
                >
                  {cnt_moe ? cnt_moe : null}
                </Text>
              </Box>
            </Grid>
          </Text>
        ) : null,
        percent: pct ? (
          <Text size={getRelativeBodySize("xsmall", screenSize)}>
            <Grid fill columns={["3fr", "1fr", "3fr"]}>
              <Box fill>
                <Text
                  size={getRelativeBodySize("xsmall", screenSize)}
                  alignSelf="end"
                >
                  {pct}
                </Text>
              </Box>
              <Box fill>
                <Text
                  size={getRelativeBodySize("xxsmall", screenSize)}
                  color="dark-5"
                >
                  {pct_moe ? <>&nbsp;&plusmn;&nbsp;</> : null}
                </Text>
              </Box>
              <Box fill>
                <Text
                  alignSelf="start"
                  size={getRelativeBodySize("xxsmall", screenSize)}
                  color="dark-5"
                >
                  {pct_moe ? pct_moe : null}
                </Text>
              </Box>
            </Grid>
          </Text>
        ) : null,
      };
    })
    .filter((e) => e.count || e.percent);

  let columns = [
    {
      property: "label",
      header: "",
      primary: true,
      render: (datum) => (
        <Text size={getRelativeBodySize("xsmall", screenSize)} weight="bold">
          {datum.label}
        </Text>
      ),
    },
  ];
  if (screenSize !== "mobile") {
    columns.push({
      property: "count",
      header: (
        <Text size={getRelativeBodySize("xsmall", screenSize)} weight="bold">
          Count
        </Text>
      ),
      align: "center",
    });
  }
  columns.push({
    property: "percent",
    header: (
      <Text size={getRelativeBodySize("xsmall", screenSize)} weight="bold">
        Percent
      </Text>
    ),
    align: "center",
  });

  const filteredData =
    screenSize === "mobile"
      ? data.filter(({ label }) => label !== "Population")
      : data;

  return (
    <Box>
      {onCloseButtonClick ? (
        <Box direction="row" justify="between" gap="xsmall" align="start">
          <Heading fill margin="0 0 .25em 0" color="#333">
            {header}
          </Heading>
          <Button
            size="small"
            plain
            icon={<CloseIcon size="21px" />}
            tip={"Deselect " + header.split(",")[0]}
            onClick={onCloseButtonClick}
          />
        </Box>
      ) : (
        <Heading margin="0 0 .25em 0" color="#333">
          {header}
        </Heading>
      )}
      <DataTable
        columns={columns}
        data={filteredData}
        step={10}
        pad="xxsmall"
        sortable={false}
        background={{
          header: "light-4",
          body: ["white", "light-1"],
          footer: "dark-3",
        }}
        border={{
          body: { side: "bottom", color: "light-2" },
          header: { side: "bottom", color: "light-4" },
        }}
      />
      {/* </Box> */}
    </Box>
  );
};
export default HeDataTable;
