import React, { useEffect, useState } from "react";
import { useViewport, getRelativeBodySize } from "@headwaters-economics/web-shared";
import { Box, Text, RangeInput } from "grommet";

const currentDate = new Date();
const currentYear = parseInt(currentDate.getFullYear(), 10);

const YearRangeSelector = ({ startValue, onChangeComplete }) => {
    const [yearSpan_temp, set_yearSpan_temp] = useState(startValue);
    const { screenSize } = useViewport();

    //fire the onChangeComplete event after the user has paused for one second
    useEffect(() => {
        if (startValue === yearSpan_temp) return;
        const timeout = setTimeout(() => {
            onChangeComplete(yearSpan_temp);
        }, 1000);

        return () => clearTimeout(timeout);
    }, [yearSpan_temp, onChangeComplete, startValue]);

    const endYear = currentYear + yearSpan_temp;
    return (
        <Box width="large" border={{size:'small'}}>
            <Box direction="row" justify="between">
                <Box background="light-2" basis="3/4" pad={{ vertical: "xsmall", horizontal: "small" }}>
                    <Box margin={{ left: "2.5%", right: "3%" }} pad={{ vertical: "xsmall"}}>
                        <RangeInput
                            min={1}
                            max={70}
                            value={yearSpan_temp}
                            onChange={(event) => {
                                set_yearSpan_temp(parseInt(event.target.value, 10));
                            }}
                        />
                    </Box>
                    <Box direction="row" justify="between">
                        {[0, 10, 20, 30, 40, 50, 60, 70].map((timeSpan) => (
                            <Box key={timeSpan} pad={{ horizontal: "xsmall" }} border={false}>
                                <Text size={getRelativeBodySize("small", screenSize)}>{timeSpan}</Text>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box basis="1/4" background="light-2" border={{side:'left',color:'dark-2'}} pad={{ vertical: "xsmall", horizontal: "small" }}>
                    <Text weight="bold" size={getRelativeBodySize("large", screenSize)} textAlign="center">
                        {yearSpan_temp} Years
                        <br />
                        <Text weight="normal">({endYear})</Text>
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};

export default YearRangeSelector;
