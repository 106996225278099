import React, { useContext } from "react";
import {
  Grid,
  Tab,
  Tabs,
  Text,
  Box,
  Header,
  Main,
  Footer,
  Image,
  Menu,
  Button,
  Layer,
  Anchor,
} from "grommet";

import ReactPlayer from "react-player";

import appContext from "../context/appContext";
import mapTabContext from "../context/mapTabContext";

import { useViewport, useMountEffect } from "@headwaters-economics/web-shared";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBug,
  faBars,
  faInfoCircle,
  faPlayCircle,
  faTimesCircle,
} from "@fortawesome/pro-regular-svg-icons";

import heLogo from "../assets/images/HE_Logo_Horizontal_Color.svg";

import LocationPicker from "@headwaters-economics/web-shared/components/LocationPicker";

import { useLocation } from "react-router-dom";

import heLogoIcon from "../assets/images/HE-LogoIcon.svg";

const PartnerLinks = ({ name, url }) => {
  return (
    <>
      &nbsp;
      <Anchor
        className="footer-link"
        href={url}
        a11yTitle={"visit " + name}
        label={name}
      />
    </>
  );
};

const FooterText = ({ size }) => {
  const { appTab } = useContext(appContext);
  return (
    <Text size={size}>
      This free tool is nationally available thanks to generous contributions
      from the
      <PartnerLinks
        name="USDA Forest Service"
        url="https://www.fs.usda.gov/"
      />
      ,
      <PartnerLinks
        name="Tableau Foundation"
        url="https://www.tableau.com/foundation"
      />
      ,
      <PartnerLinks name="Mapbox" url="https://www.mapbox.com/community/" />,
      <PartnerLinks
        name="Urban Sustainability Directors Network"
        url="https://www.usdn.org/index.html#/"
      />
      ,
      <PartnerLinks
        name="M. J. Murdock Charitable Trust"
        url="https://murdocktrust.org/"
      />
      ,
      <PartnerLinks
        name="Climate Resilience Fund"
        url="https://www.climateresiliencefund.org/"
      />
      ,
      <PartnerLinks
        name="National Oceanic and Atmospheric Administration"
        url="https://cpo.noaa.gov/divisions-programs/communication-education-and-engagement/"
      />
      , and
      <PartnerLinks
        name="National Academy of Sciences, Engineering, and Medicine"
        url="https://www.nationalacademies.org/gulf/gulf-research-program"
      />
      .
      {appTab === "climate" && (
        <>
          &nbsp;Climate data accessed via&nbsp;
          <PartnerLinks
            name="Applied Climate Information System (ACIS)"
            url="https://rcc-acis.org"
          />{" "}
          managed by the Northeast Regional Climate Center, &nbsp;Cornell
          University.
        </>
      )}
    </Text>
  );
};

const MobileFooter = () => {
  const [show, setShow] = React.useState();

  return (
    <Box pad="xsmall">
      <Button
        icon={<FontAwesomeIcon icon={faInfoCircle} />}
        label="info"
        plain
        gap="xsmall"
        onClick={() => setShow(true)}
      />
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          position="center"
          fill
          responsive={false}
        >
          <Box
            pad="large"
            gap="medium"
            width="medium"
            background="light-2"
            alignSelf="center"
            justifySelf="center"
          >
            <FooterText size="small" />
            <Button label="close" onClick={() => setShow(false)} />
          </Box>
        </Layer>
      )}
    </Box>
  );
};

function ExploreScreen({ tab }) {
  const { setLocation, setAppTab, appErrorMessage, selectedLocationMetadata } =
    useContext(appContext);
  const { setDownloadModalProps, tracts } = useContext(mapTabContext);
  const { screenSize, appWidth } = useViewport();
  const location = useLocation();
  const locParts = location.pathname.split("/");
  const [show, setShow] = React.useState();

  useMountEffect(() => {
    setAppTab(locParts[2] === "explore" && locParts[3] === "climate" ? 1 : 0);
  });

  //return a error indicator if there is a problem fetching data
  if (appErrorMessage) {
    return (
      <Box background="lightgrey" fill justify="center" align="center">
        <FontAwesomeIcon icon={faBug} size="6x" />
        <Text>{appErrorMessage}</Text>
      </Box>
    );
  }

  let menuItems = [
    {
      label: "Headwaters Economics",
      href: "https://headwaterseconomics.org",
      pad: "none",
    },
    {
      label: "About this tool",
      href: "https://headwaterseconomics.org/tools/neighborhoods-at-risk/tool-about/",
    },
    { label: "See more tools", href: "https://headwaterseconomics.org/tools" },
    { label: "Contact us", href: "https://headwaterseconomics.org/contact/" },
    {
      label: (
        <Box
          background={"brand"}
          direction="row"
          justify="start"
          gap="xsmall"
          align="center"
          pad={{ vertical: "xxsmall", horizontal: "small" }}
        >
          <FontAwesomeIcon icon={faPlayCircle} />
          <Text>How to use this tool</Text>
        </Box>
      ),
      onClick: () => setShow(true),
    },
  ];
  if (screenSize === "mobile" && tracts.length > 0) {
    menuItems.push({
      label: "Download report",
      onClick: () =>
        setDownloadModalProps({
          label: "Download a socioeconomic report for selected locations.",
          tracts: tracts,
        }),
    });
  }

  return (
    <Grid
      fill={
        locParts[3] === "climate" && screenSize !== "desktop"
          ? "horizontal"
          : true
      }
      rows={["auto", "flex", "auto"]}
    >
      <Header
        align="start"
        justify="between"
        background="white"
        direction="row"
        gap="none"
      >
        <Box direction="row-responsive" justify="between" fill="horizontal">
          <Box direction="row" alignContent="center">
            <Box
              border={{ side: "right", color: "light-3", size: "xsmall" }}
              margin={{ horizontal: "8px", vertical: "8px" }}
              pad={{ right: "8px" }}
              align="center"
              width={{ min: "fit-content" }}
              height="50px"
            >
              <Button
                plain
                href="https://headwaterseconomics.org"
                height={{ max: "fit-content" }}
              >
                <img
                  className="logo-icon"
                  height="50px"
                  width="50px"
                  src={heLogoIcon}
                  alt="Headwaters logo"
                />
              </Button>
            </Box>
            <Button alignSelf="center" plain href="/">
              <Box
                direction="column"
                pad={{ right: "small" }}
                margin={{ bottom: "-2px" }}
                width={{ min: "fit-content" }}
              >
                <Text weight="900">Neighborhoods</Text>
                <Text weight={"400"}>at Risk</Text>
              </Box>
            </Button>
            {/* <Box pad="xsmall">
              <Box
                width={{ min: "medium", max: "large" }}
                // margin='small'
                //pad={{ vertical: "4px" }}
                fill="vertical"
                alignSelf="center"
                id={"SearchHeaderContainer"}
                overflow="visible"
                border
              > */}
              <Box justify="center">
            {/* <Text size="24px"> */}
              <LocationPicker
                border={{color:'light-3'}}
                currentSelection={
                  selectedLocationMetadata
                    ? selectedLocationMetadata.label
                    : null
                }
                onSelection={(place) => setLocation(place, locParts[3])}
                geoTypes={["he-place", "he-county", "he-state"]}
                width="medium"
              />
            {/* </Text> */}
            </Box>
          </Box>
          {/* </Box>
          </Box> */}
          <Box alignSelf="center">
            {appWidth >= "1400" && (
              <Anchor onClick={() => setShow(true)}>
                <Box
                  direction="row"
                  gap="xsmall"
                  align="center"
                  background="light-2"
                  pad={{ vertical: "xsmall", horizontal: "small" }}
                  round="xsmall"
                  animation={["fadeIn"]}
                >
                  <FontAwesomeIcon icon={faPlayCircle} />
                  <Text color="dark-1">How to use this tool</Text>
                </Box>
              </Anchor>
            )}
            {show && (
              <Layer
                onEsc={() => setShow(false)}
                onClickOutside={() => setShow(false)}
                background="dark-1"
              >
                <Box round="xsmall" overflow="hidden" background="dark-1">
                  <Box direction="row" justify="between" pad="small">
                    <Text>How to use Neighborhoods at Risk</Text>
                    <Anchor
                      onClick={() => setShow(false)}
                      label={<FontAwesomeIcon icon={faTimesCircle} size="lg" />}
                    />
                  </Box>
                  <ReactPlayer
                    controls="true"
                    width="1280px"
                    height="720px"
                    url="https://vimeo.com/577801267"
                  />
                </Box>
              </Layer>
            )}
          </Box>
          <Box pad={{ right: "small" }} direction="row" gap="small">
            <Tabs
              onActive={(index) => {
                setAppTab(index);
              }}
              activeIndex={
                locParts[2] === "explore" && locParts[3] === "climate" ? 1 : 0
              }
              alignSelf="center"
              pad={{ horizontal: "xsmall" }}
              justify={screenSize === "mobile" ? "start" : "center"}
            >
              <Tab title="Explore Neighborhoods" />
              <Tab title="Climate Projections" />
            </Tabs>
          </Box>
        </Box>
        <Box align="middle" alignSelf="center" pad="small">
          <Menu
            dropProps={{
              align: { top: "bottom", left: "right" },
              elevation: "large",
              pad: { horizontal: "none" },
            }}
            icon={<FontAwesomeIcon icon={faBars} size="lg" />}
            justifyContent="end"
            plain
            items={menuItems}
          />
        </Box>
      </Header>
      <Main>{tab}</Main>
      <Footer
        align="center"
        justify="between"
        pad={{ horizontal: "xsmall", vertical: "xsmall" }}
        background="light-2"
        border={{
          side: "top",
          style: "solid",
          width: "small",
          color: "light-4",
        }}
        gap="xsmall"
      >
        <Grid columns={['1fr','auto']}>
          {screenSize === "mobile" ? (
            <MobileFooter />
          ) : (
            <FooterText size="xsmall" />
          )}

          <Box pad="xsmall">
            <Image
              width={screenSize === "mobile" ? "100%" : "300px"}
              fit="contain"
              src={heLogo}
            />
          </Box>
        </Grid>
        
      </Footer>
    </Grid>
  );
}

export default ExploreScreen;
