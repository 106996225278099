import React, { useContext } from "react";
import mapTabContext from "../../../context/mapTabContext";
import parameterLU from "../../../assets/data/parameterLU.json";

import { BarChart, Bar, ResponsiveContainer } from "recharts";
import appColors from "../../../appColors";
import { Box, Grid, Text } from "grommet";
import map from "lodash/map";
import countBy from "lodash/countBy";
import filter from "lodash/filter";

import { RangeInput } from "grommet";

const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 1,
});

function Filter({ parameterKey }) {
  const {
    tractLevelData,
    tracts,
    filters,
    setFilterValueDebounce,
    setSliderValue,
    setEquityCriteria,
    isFilteringDisabled,
  } = useContext(mapTabContext);

  function onChange(event) {
    const val = parseFloat(event.target.value);
    setFilterValueDebounce(parameterKey, val);
    setSliderValue(parameterKey, val);
    setEquityCriteria(null);
  }

  const filterData = filters.find(({ key }) => parameterKey === key);
  const tractsWithData = tracts.filter(
    (tract) => tractLevelData[tract.id][parameterKey] !== "unavailable"
  );

  const data = map(tractsWithData, (tract) => {
    return {
      id: tract.id,
      includeTract: tract.include,
      ...tractLevelData[tract.id][parameterKey],
    };
  });

  const countsByBinIndex_included = countBy(
    filter(data, { includeTract: true }),
    (item) => {
      return Math.floor(
        (filterData.binCnt * (item.pct - filterData.sliderMin)) /
          filterData.range
      );
    }
  );
  const countsByBinIndex_excluded = countBy(
    filter(data, { includeTract: false }),
    (item) => {
      return Math.floor(
        (filterData.binCnt * (item.pct - filterData.sliderMin)) /
          filterData.range
      );
    }
  );
  const bins = Array.from(Array(filterData.binCnt)).map((v, i) => {
    return {
      bar: i,
      included: countsByBinIndex_included[i] || 0,
      excluded: countsByBinIndex_excluded[i] || 0,
    };
  });

  const labelMargin =
    -150 +
    300 * ((filterData.sliderValue - filterData.sliderMin) / filterData.range);

  const SingleTractValue = () => {
    const tractData =
      tractLevelData[Object.keys(tractLevelData)[0]][parameterKey];
    if (tractData !== "unavailable" && "pct" in tractData)
      return percentFormatter.format(tractData.pct);
    return tractData.value;
  };

  return (
    <Grid
      pad={{ bottom: "xsmall" }}
      columns={isFilteringDisabled ? ["70%", "30%"] : ["1/2", "1/2"]}
      gap="xxsmall"
    >
      <Box pad={{ top: "20px" }}>
        <Text
          textAlign="start"
          size="small"
          style={
            parameterLU[parameterKey].desc
              ? { textDecoration: "underline dotted" }
              : {}
          }
          tip={
            parameterLU[parameterKey].desc
              ? { content: parameterLU[parameterKey].desc, dropProps: { width: "medium" }}
              : null
          }
        >
          {parameterLU[parameterKey].label}
        </Text>
        {tractsWithData.length === 0 && (
          <Box pad={{ left: "small" }}>
            <Text textAlign="start" size="small" color="darkred">
              <i>No data available</i>
            </Text>
          </Box>
        )}
      </Box>
      {isFilteringDisabled ? (
        <Box pad={{ top: "20px" }} direction="row">
          <Text color="dark-4" textAlign="center">
            <SingleTractValue />
          </Text>
        </Box>
      ) : (
        <Box width="300px">
          <ResponsiveContainer width={"100%"} height={25}>
            <BarChart data={bins}>
              <Bar dataKey="included" stackId="a" fill={appColors.included} />
              <Bar dataKey="excluded" stackId="a" fill={appColors.excluded} />
            </BarChart>
          </ResponsiveContainer>
          <RangeInput
            direction="horizontal"
            min={filterData.sliderMin}
            max={filterData.sliderMax}
            step={filterData.step}
            size="full"
            value={filterData.sliderValue}
            onChange={onChange}
            disabled={tractsWithData.length === 0}
          />
          <Text
            color="dark-4"
            margin={{ left: labelMargin.toString() + "px" }}
            textAlign="center"
            size="xsmall"
          >
            {"> "}
            {percentFormatter.format(filterData.sliderValue)}
          </Text>
        </Box>
      )}
    </Grid>
  );
}

export default Filter;
