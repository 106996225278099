import React, { useContext } from "react";
import DataTable from "../DataTable";
import { Box, Button } from "grommet";
import { Popup } from "@headwaters-economics/web-shared/components/Map";
import mapTabContext from "../../../context/mapTabContext";
import parameterLU from "../../../assets/data/parameterLU.json";

const Tooltip = ({ hoverFeature, isPopupPinned, highlightFeature, popupPosition,onCloseButtonClick}) => {
  const {
    filters,
    tractLevelData,
    setDownloadModalProps,
  } = useContext(mapTabContext);


  const PopupContent = () => {
    const tractInfo = tractLevelData[hoverFeature.id];
    const tblHeader = hoverFeature.properties.name;
    let tblData = [
      {
        key: "population",
        label: "Population",
        value: tractInfo["population"].value,
      },
    ];
    ["equity", "exposure"].forEach((filterType) => {
      filters
        .filter((f) => f.type === filterType && f.visible)
        .forEach((f) => {
          tblData.push({
            key: f.key,
            label: parameterLU[f.key].label,
            value: tractInfo[f.key] ? tractInfo[f.key].value : null,
            moe: tractInfo[f.key] ? tractInfo[f.key].moe : null,
            pct: tractInfo[f.key] ? tractInfo[f.key].pct : null,
            pct_moe: tractInfo[f.key] ? tractInfo[f.key].pct_moe : null,
          });
        });
    });

    return (
      <Box
        pad="xsmall"
        elevation={isPopupPinned ? "large" : "small"}
        //className={isPopupPinned ? "narPopup_pinned" : "narPopup_floating"}
      >

        <Box>
          <DataTable
            header={tblHeader}
            rows={tblData}
            population={tractInfo.population.value}
            onCloseButtonClick={onCloseButtonClick}
          />
        </Box>
        {isPopupPinned && highlightFeature && (
          <Button
            style={{ fontSize: "0.95em", borderRadius: "0px" }}
            label={
              "Download a report for " + highlightFeature.name.split(",")[0]
            }
            onClick={() =>
              setDownloadModalProps({
                label:
                  "Download a socioeconomic report for " +
                  highlightFeature.name,
                tracts: [
                  {
                    id: highlightFeature.id,
                    name: highlightFeature.name,
                    type: "mapClick",
                  },
                ],
              })
            }
          />
        )}
      </Box>
    );
  };

  return (
      <Popup
        {...popupPosition}
        isPopupPinned={isPopupPinned}
        maxWidth="400px"
      >
        {hoverFeature && popupPosition && <PopupContent />}
      </Popup>
  );
};

export default Tooltip;
