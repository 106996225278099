import React, { useState, useContext, useRef } from "react";

import { useViewport, getRelativeBodySize, getRelativeHeadingSize } from "@headwaters-economics/web-shared";

import { Box, Grid, Button, Heading, Layer, Text } from "grommet";
import { Close as CloseIcon } from "grommet-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/pro-light-svg-icons";
import Loader from "../Loader";
import Map from "./Map";
import MapSidebar from "./MapSidebar";
import appContext from "../../context/appContext";
import mapTabContext from "../../context/mapTabContext";
import DataTable from "./DataTable";
import DownloadModal from "./DownloadModal";
import styled from "styled-components";

import parameterLU from "../../assets/data/parameterLU.json";

const StyledButton = styled(Button)`
    border: solid 2px;
    border-radius: 3px;
    padding: 0.25em 0.5em;
    font-weight: 700;
    text-align: center;
    color: #3d7199;
    font-size: 1.25em;
    line-height: 18px;
    &:hover {
        border: solid 2px #3d7199;
        background: #3d7199;
        color: #f2f2f2;
    }
`;


function MapTab() {
    const { selectedLocationMetadata } = useContext(appContext);
    const { tracts, filters, tractLevelData, selectedCriteria, isFilteringDisabled } = useContext(
        mapTabContext
    );
    const [isCombinedDataTableVisible, set_isCombinedDataTableVisible] = useState(true);
    const [isMobileSidebarVisible, set_isMobileSidebarVisible] = useState(false);
    const { screenSize } = useViewport();

    const mapParent = useRef(null);
    const mapTabParent = useRef(null);

    if (!selectedLocationMetadata || !tractLevelData) return <Loader />;

    let shortName = selectedLocationMetadata.label;
    if (selectedLocationMetadata.geo_level !== 'state'){
        shortName = shortName.substring(0, selectedLocationMetadata.label.length - 4);
    }

    const CombinedDataTable = () => {
        if (!isCombinedDataTableVisible) {
            return (
                <Box background="dark-1" pad="xsmall" animation="fadeIn">
                    <Button
                        size="medium"
                        plain
                        icon={<FontAwesomeIcon icon={faTable} size="lg" />}
                        margin="none"
                        onClick={() => {
                            set_isCombinedDataTableVisible(true);
                        }}
                    />
                </Box>
            );
        }

        const visibleTractIds = tracts.filter((e) => e.include).map((e) => e.id);

        const CombinedDataHeader = () => {
            return (
                <Box flex={false} direction="row" justify="between" align="center">
                    <Box direction="row" gap="xsmall" align="center">
                        <FontAwesomeIcon icon={faTable} size="lg" />
                        <Heading size={getRelativeHeadingSize("xsmall", screenSize)} level={3} margin="0">
                            Combined Results ({visibleTractIds.length} tract{visibleTractIds.length === 1 ? "" : "s"})
                        </Heading>
                    </Box>
                    <Button plain icon={<CloseIcon size="medium" />} onClick={() => set_isCombinedDataTableVisible(false)} />
                </Box>
            );
        };

        if (visibleTractIds.length === 0) {
            return (
                <Box width={{ max: "400px" }} pad="xsmall" elevation="medium" animation="fadeIn" background="white">
                    <CombinedDataHeader />
                    <Box>
                        <Text size={getRelativeBodySize("small", screenSize)} pad="small">
                            <i>No tracts meet your criteria. Try updating the filters.</i>
                        </Text>
                    </Box>
                </Box>
            );
        }

        let tblData = [
            {
                key: "population",
                label: "Population",
                value: visibleTractIds.map((id) => tractLevelData[id].population.value).reduce((a, b) => a + b),
            },
        ];
        //add the equity data
        filters
            .filter((f) => f.type === "equity" && f.visible)
            .forEach((f) => {
                const tractsWithData = visibleTractIds.filter(
                    (id) => tractLevelData[id][f.key] && tractLevelData[id][f.key].world_value
                );

                if (tractsWithData.length > 0) {
                    // see https://www.census.gov/content/dam/Census/library/publications/2018/acs/acs_general_handbook_2018_ch08.pdf
                    // note: we are using the formula for calculating the MOE for ratios instead of proportions since that is what was used in the report build app

                    // arrays of raw values
                    const array_cnt = tractsWithData.map((id) => tractLevelData[id][f.key].value);
                    const array_cnt_world = tractsWithData.map((id) => tractLevelData[id][f.key].world_value);
                    const array_moe = tractsWithData.map((id) => tractLevelData[id][f.key].moe);
                    const array_moe_world = tractsWithData.map((id) => tractLevelData[id][f.key].world_moe);

                    // intermediate values and arrays
                    const array_moe_squared = array_moe.map((value) => value * value);
                    const array_moe_world_squared = array_moe_world.map((value) => value * value);
                    const rootSumSquare_moe = Math.sqrt(array_moe_squared.reduce((a, b) => a + b));
                    const rootSumSquare_world_moe = Math.sqrt(array_moe_world_squared.reduce((a, b) => a + b));

                    //derived values
                    const derived_cnt = array_cnt.reduce((a, b) => a + b);
                    const derived_world_cnt = array_cnt_world.reduce((a, b) => a + b);
                    const derived_prop = derived_cnt / derived_world_cnt;
                    const derived_prop_moe =
                        (1 / derived_world_cnt) *
                        Math.sqrt(
                            Math.pow(rootSumSquare_moe, 2) +
                                Math.pow(derived_prop, 2) * Math.pow(rootSumSquare_world_moe, 2)
                        );

                    tblData.push({
                        key: f.key,
                        label: parameterLU[f.key].label,
                        value: derived_cnt,
                        moe: rootSumSquare_moe,
                        pct: derived_prop,
                        pct_moe: derived_prop_moe,
                    });
                }
            });

        // add the exposure data
        filters
            .filter((f) => f.type === "exposure" && f.visible)
            .forEach((f) => {

                const tractsWithData = visibleTractIds.filter(
                    (id) => tractLevelData[id][f.key] && tractLevelData[id][f.key] !== 'unvailable' && tractLevelData[id][f.key] !== 'unavailable' &&"pct" in tractLevelData[id][f.key]
                );
                if (tractsWithData.length > 0) {
                    const array_total_area = tractsWithData.map((id) => tractLevelData[id].area);
                    const array_percent = tractsWithData.map((id) => tractLevelData[id][f.key].pct);
                    const totalArea = array_total_area.reduce((a, b) => a + b);
                    let areaIncluded = 0;
                    array_total_area.forEach((a, i) => {
                        areaIncluded += (array_total_area[i] * array_percent[i]) / 100;
                    });
                    const combinedPct = (100 * areaIncluded) / totalArea;

                    tblData.push({
                        key: f.key,
                        label: parameterLU[f.key].label,
                        pct: combinedPct,
                    });
                }
            });

        const population = visibleTractIds.map((id) => tractLevelData[id].population.value).reduce((a, b) => a + b);
        return (
            <Box width={{ max: "400px" }} pad="xsmall" elevation="medium" animation="fadeIn" background="light-1">
                <DataTable header={<CombinedDataHeader />} rows={tblData} population={population} />
            </Box>
        );
    };

    const HeaderContainer = () => {
        const tractCount = tracts.filter((e) => e.include).length;
        if (isFilteringDisabled) {
            return (
                <Box pad="small" background="light-4" margin="0 0 0 0">
                    <Text size={getRelativeBodySize("large", screenSize)}>
                        {shortName}
                        {" intersects "}
                        <Text color="heBlue-2" weight={900} size={getRelativeBodySize("large", screenSize)}>
                            {tractCount} census tract{tractCount > 1 ? "s" : ""}
                        </Text>
                        {". Hover over " +
                            (tracts.length === 1 ? "the" : "each") +
                            " tract to view characteristics that indicate vulnerability to climate change."}
                    </Text>
                </Box>
            );
        }
        return (
            <Box pad="small" background="light-4" margin="0 0 0 0">
                <Text size={getRelativeBodySize("large", screenSize)}>
                    {shortName}
                    {" intersects "}
                    <Text color="heBlue-2" weight={900} size={getRelativeBodySize("large", screenSize)}>
                        {tractCount} census tract{tractCount > 1 ? "s" : ""}
                    </Text>
                    {selectedCriteria === "None"
                        ? ". Set criteria below to find at-risk tracts."
                        : !selectedCriteria
                        ? " where vulnerabilities to climate change exceed the selected criteria."
                        : selectedCriteria === "Median"
                        ? " where vulnerabilities to climate change exceed the " +
                          (selectedLocationMetadata.geo_level === "county" ? "county" : selectedLocationMetadata.geo_level ===  "state" ? 'state' : "community") +
                          " median. "
                        : selectedCriteria === "U.S. Average"
                        ? " where vulnerabilities to climate change exceed the U.S. average."
                        : ""}
                </Text>
            </Box>
        );
    };

    if (screenSize === "mobile") {
        return (
            <Grid fill rows={isFilteringDisabled ? ["auto", "flex"] : ["auto", "flex", "60px"]} ref={mapTabParent}>
                <HeaderContainer />
                <Box fill ref={mapParent} justify="center" align="center" animation="fadeIn">
                    <Map
                        isCombinedDataTableVisible={isCombinedDataTableVisible}
                        CombinedDataTable={CombinedDataTable}
                    />
                    {isMobileSidebarVisible && mapParent && (
                        <Layer
                            full
                            plain
                            animation="slide"
                            responsive={false}
                            modal={false}
                            position={"left"}
                            target={mapParent.current}
                        >
                            <Box fill background="heBlue-3" pad={{ vertical: "none" }}>
                                <MapSidebar />
                            </Box>
                        </Layer>
                    )}
                </Box>
                {!isFilteringDisabled && (
                    <StyledButton
                        plain
                        label={isMobileSidebarVisible ? "Apply Filters" : "Update Filters"}
                        onClick={() => set_isMobileSidebarVisible(!isMobileSidebarVisible)}
                    />
                )}
                <DownloadModal />
                {/* {isMapLoaderVisible && mapTabParent.current && (
                    <ThemeContext.Extend value={customLayerTheme}>
                        <Layer
                            responsive={false}
                            full
                            plain
                            animation="none"
                            modal={false}
                            target={mapTabParent.current}
                        >
                            <Loader background="#cccccce6" />
                        </Layer>
                    </ThemeContext.Extend>
                )} */}
            </Grid>
        );
    }

    return (
        <Grid
            gridArea="appMain"
            fill
            rows={["auto", "flex"]}
            columns={["400px", "flex"]}
            areas={[
                ["header", "header"],
                ["sidebar", "map"],
            ]}
            ref={mapTabParent}
        >
            <Box gridArea="header">
                <HeaderContainer />
            </Box>
            <Box gridArea="sidebar" overflow={{ vertical: "auto" }} elevation="medium" style={{zIndex:10}} >
                <MapSidebar />
            </Box>

            <Box fill="vertical" ref={mapParent} justify="center" align="center" animation="fadeIn" gridArea="map">
                <Map isCombinedDataTableVisible={isCombinedDataTableVisible} CombinedDataTable={CombinedDataTable} />
                <DownloadModal />
            </Box>
            {/* {isMapLoaderVisible && mapTabParent.current && (
                <ThemeContext.Extend value={customLayerTheme}>
                    <Layer responsive={false} full plain animation="none" modal={false} target={mapTabParent.current}>
                        <Loader background="#cccccce6" />
                    </Layer>
                </ThemeContext.Extend>
            )} */}
        </Grid>
    );
}

export default MapTab;
