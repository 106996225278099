import React, { useContext, useEffect, useState } from "react";
import { useViewport, getRelativeBodySize } from "@headwaters-economics/web-shared";
import styled from "styled-components";
import mapTabContext from "../../context/mapTabContext";
import { Box, Button, Text } from "grommet";
import { Close as CloseIcon, Refresh } from "grommet-icons";
import parameterLU from "../../assets/data/parameterLU.json";

const percentFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 1
});

const PillButton = styled(Button)`
    padding: .25em .5em;
    margin: 0 .25em .25em 0;
    background-color: #f2f2f2;
    border: solid thin;
    color: #777;
    &:hover {
        border: solid thin;
    }
`;

const ResetButton = styled(Button)`
    padding: .25em .5em;
    margin: 0 .25em .25em 0;
    background-color: #777;
`;

const FilterPills = () => {
    const { setFilterValue, setSliderValue, setEquityCriteria, filters} = useContext(mapTabContext);
    const [activeFilters, set_activeFilters] = useState([]);
    const { screenSize } = useViewport();
    useEffect(()=>{
        if (filters.length>0){
            set_activeFilters(filters.filter(f=>f.applied && f.visible))
        }
    },[filters])

    if (activeFilters.length === 0) return null;
    return (
        <Box direction="row" wrap={true}>
            {activeFilters.length > 1 && 
                <ResetButton 
                    size="small" 
                    gap={"xxsmall"} 
                    label={<Text size={getRelativeBodySize("xsmall", screenSize)}>RESET FILTERS</Text>}  
                    icon={<Refresh size={getRelativeBodySize("xsmall", screenSize)} />}
                    onClick={()=>{
                        activeFilters.forEach(filter=>{
                           setFilterValue(filter.key, filter.sliderMin);
                           setSliderValue(filter.key, filter.sliderMin);
                        })
                        setEquityCriteria('None')
                    }} 
                />}
            {activeFilters.map((filter) => {
                const value = filters.find(f=>f.key===filter.key).filterValue;
                return (
                    <PillButton
                        size="small"
                        gap={"xsmall"}
                        key={filter.key}
                        label={<Text size={getRelativeBodySize("xsmall", screenSize)}>{parameterLU[filter.key].label}&nbsp;&gt;{percentFormatter.format(value)}</Text>}
                        reverse
                        icon={<CloseIcon size={getRelativeBodySize("xsmall", screenSize)} color="#777" />}
                        onClick={() => {
                            setFilterValue(filter.key, filter.sliderMin);
                            setSliderValue(filter.key, filter.sliderMin);
                            setEquityCriteria(null)
                        }}
                    />
                );
            })}
        </Box>
    );
};
export default FilterPills;
