import React, { useContext } from "react";
import appContext from "../../context/appContext";
import { Box, Button, Heading, Layer } from "grommet";
import { useViewport, getRelativeHeadingSize } from "@headwaters-economics/web-shared";
import { Close as CloseIcon } from "grommet-icons";

const ClimateWarningForStates = () => {
  const {
    appTab,
    selectedLocationMetadata,
    isClimateWarningAvailableForLocation,
    setIsClimateWarningAvailableForLocation,
  } = useContext(appContext);
  const { screenSize } = useViewport();

  if (
    !isClimateWarningAvailableForLocation ||
    appTab !== "climate" ||
    !selectedLocationMetadata ||
    selectedLocationMetadata.geo_level !== "state"
  ) {
    return null;
  }

  return (
    <Layer
      onEsc={() => setIsClimateWarningAvailableForLocation(false)}
      onClickOutside={() => setIsClimateWarningAvailableForLocation(false)}
    >
      <Box
        flex={false}
        direction="row"
        justify="between"
        align="center"
        background="dark-2"
        pad={"small"}
      >
        <Heading size={getRelativeHeadingSize("medium", screenSize)}>
          Warning
        </Heading>
        <Button
          plain
          icon={<CloseIcon />}
          onClick={() => setIsClimateWarningAvailableForLocation(false)}
        />
      </Box>
      <Box>
        <Box pad="small">
          Across any state, climate projections may vary. This is especially
          true for large states. Consider selecting a county to explore
          anticipated changes in heat and precipitation.
        </Box>
      </Box>
    </Layer>

  );
};

export default ClimateWarningForStates;
