/* eslint-disable */

import React from "react";
import "./App.scss";
import { Grommet, Box } from "grommet";
import { HeMainTheme } from "@headwaters-economics/web-shared";
import { deepMerge } from "grommet/utils";
import AppProvider from "./context/AppProvider";
import MapTabProvider from "./context/MapTabProvider";
import { ViewportProvider } from "@headwaters-economics/web-shared";
import StartScreen from "./screens/StartScreen";
import ExploreScreen from "./screens/ExploreScreen";
import { Helmet } from "react-helmet";
import GoogleAnalytics from "@headwaters-economics/web-shared/components/GoogleAnalyticsWithRouter";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import MapTab from "./components/MapTab";
import ClimateTab from "./components/ClimateTab";

const narTheme = deepMerge(HeMainTheme, {
  global: {
    spacing: "10px",
    font: {
      family: "Source Sans Pro, sans-serif",
    },
  },
  anchor: {
    color: {
      dark: "light-2",
      light: "dark-2",
    },
  },
  select: {
    step: 10,
    icons: {
      color: "dark-1",
    },
    background: "#ffffff",
  },
  textInput: {
    size: "1.5rem",
  },
  tabs: {
    gap: ".5em",
  },
  tab: {
    hover: {
      background: "light-2",
    },
    active: {
      weight: 700,
    },
    border: { size: "none" },
    pad: "xsmall",
    margin: "none",
  },
  rangeInput: {
    track: {
      height: "5px",
      // color: 'accent-1',
      lower: {
        color: "rgb(150,150,150)",
        opacity: 0.2,
      },
      upper: {
        color: "rgb(61,113,153)",
        opacity: 0.5,
      },
    },
    thumb: {
      color: "rgb(61,113,153)",
    },
  },
  rangeSelector: {
    edge: {
      type: "bar",
    },
  },
  accordion: {
    background: "dark-2",
    icons: {
      color: {
        dark: "#f2f2f2",
        light: "text",
      },
    },
    border: {
      color: {
        dark: "dark-2",
        light: "text",
      },
    },
  },
  button: {
    default: {
      background: {
        color: "heDarkBlue",
      },
    },
    border: {
      color: "dark-1",
      width: "2px",
    },
  },
});

function App() {
  return (

    <Router>
      <AppProvider>
        <MapTabProvider>
          <ViewportProvider>
            <Grommet full theme={narTheme} className="app">
              <Helmet>
                <meta name="apple-mobile-web-app-capable" content="yes" />
              </Helmet>
              <GoogleAnalytics prodHostName={'nar.headwaterseconomics.org'}/>
              <Box fill>
                <Routes>
                  <Route path="/" exact element={<StartScreen />}></Route>
                  <Route
                    exact
                    path="/:placeId/explore/map"
                    element={<ExploreScreen tab={<MapTab />} />}
                  ></Route>
                  <Route
                    exact
                    path="/:placeId/explore/climate"
                    element={<ExploreScreen tab={<ClimateTab />} />}
                  ></Route>
                </Routes>
              </Box>
            </Grommet>
          </ViewportProvider>
        </MapTabProvider>
      </AppProvider>
    </Router>
  );
}

export default App;
