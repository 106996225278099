import React, { useState, useContext, useEffect } from "react";
import appContext from "../../context/appContext";
import { useViewport, getRelativeBodySize, getRelativeHeadingSize } from "@headwaters-economics/web-shared";
import { useClimateData } from "@headwaters-economics/web-shared";
import ClimateWarningForStates from "./ClimateWarningForStates";

import { Box, DropButton, Grid, Heading, Text, RadioButtonGroup } from "grommet";
import YearRangeSelector from "./YearRangeSelector";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTemperatureHot, faRaindrops, faChevronDown } from "@fortawesome/pro-light-svg-icons";

import { Area, AreaChart, XAxis, YAxis, ResponsiveContainer, ReferenceDot } from "recharts";

const currentDate = new Date();
const currentYear = parseInt(currentDate.getFullYear(), 10);

let colors = {
    heDarkBlue: "#0D4D80",
    heLightBlue: "#6580AA",
    heRed: "#820915",
    heOrange: "#D8852A",
    heGreen: "#718C49",
};

const formatDecimal = (value, range) => {
    const precision = range < 0.001 ? 4 : range < 0.01 ? 3 :range < 0.1 ? 2 : range < 1 ? 1 : 0;
    const decimalFormatter = new Intl.NumberFormat("en-US", {
        style: "decimal",
        maximumFractionDigits: precision,
    });
    return decimalFormatter.format(value);
};

const ClimateTab = () => {
    const { selectedLocationMetadata, selectedLocationId } = useContext(appContext);
    const { appHeight, screenSize } = useViewport();
    const [state, set_state] = useState({
        daysOverTemp: "95",
        daysOverPcpn: "1",
        emissionsScenario: "high",
        yearSpan: 25,
        chartsToAnimate: "none",
    });

    useEffect(() => {
        set_state((s) => {
            return { ...s, chartsToAnimate: "none" };
        });
    }, [selectedLocationId]);

    const { status: climateDataStatus, climateData /*error*/ } = useClimateData({ geoIDs: [selectedLocationId] });

    //return a loading indicator while the data is being fetched
    if (!climateData[selectedLocationId] || climateDataStatus === "fetching" || !selectedLocationMetadata) {
        return (
            <Box height={{ min: "90vh" }} background="lightgrey" fill justify="center" align="center">
                <FontAwesomeIcon icon={faSpinner} spin size="6x" />
            </Box>
        );
    }

    const endYear = currentYear + state.yearSpan;

    if (climateData[selectedLocationId] === "No climate data available")
        return (
            <Box background="lightgrey" fill justify="center" align="center">
                <Text size={getRelativeHeadingSize("large", screenSize)}>
                    Climate data is not available for {selectedLocationMetadata.label}
                </Text>
            </Box>
        );

    // calculate all of the chart data
    let allChartData = {
        hotDays: { variable: "maxt", reduce: "cnt_gt_" + state.daysOverTemp, color: colors.heRed, units: " days" },
        aveTemp: { variable: "avgt", reduce: "mean", color: colors.heRed, units: <>&#176;F</> },
        heavyRain: {
            variable: "pcpn",
            reduce: "cnt_gt_" + state.daysOverPcpn,
            color: colors.heDarkBlue,
            units: " days",
        },
        aveRain: { variable: "pcpn", reduce: "sum", color: colors.heDarkBlue, units: <>&Prime;</> },
    };
    ["hotDays", "aveTemp", "heavyRain", "aveRain"].forEach((chartKey) => {
        const data = climateData[selectedLocationId][state.emissionsScenario === "low" ? "rcp45" : "rcp85"][
            allChartData[chartKey].variable
        ][allChartData[chartKey].reduce].filter((item) => item.year >= currentYear && item.year <= endYear);
        let dataMin = null;
        let dataMax = null;
        data.forEach((item) => {
            dataMin = !dataMin ? item.ma_10 : Math.min(dataMin, item.ma_10);
            dataMax = !dataMax ? item.ma_10 : Math.max(dataMax, item.ma_10);
        });
        const startValue = data[0].ma_10;
        const endValue = data[data.length - 1].ma_10;
        const range = Math.abs(endValue - startValue);
        allChartData[chartKey].data = data;

        allChartData[chartKey].stats = {
            min: dataMin,
            max: dataMax,
            startValue: formatDecimal(startValue, range),
            endValue: formatDecimal(endValue, range),
            valueChange: formatDecimal(range, range),
            pctChange:
                startValue === 0
                    ? ""
                    : Math.abs(Math.round((100 * (endValue - startValue)) / startValue)).toString() + "%",
            changeDir: endValue > startValue ? "pos" : "neg",
        };
        });

    const StatsBlock = ({ header, para1, para2, chartData, animate }) => {
        const startDotY = Math.max(
            ...chartData.data.filter((item) => item.year < currentYear + state.yearSpan / 5).map((item) => item.ma_10)
        );
        const endDotY = Math.max(
            ...chartData.data
                .filter((item) => item.year > currentYear + state.yearSpan - state.yearSpan / 5)
                .map((item) => item.ma_10)
        );
        const yOffset = (chartData.stats.max - chartData.stats.min) / 2;
        const chartHeight = appHeight / 5;
        return (
            // <Box pad="small">
            <Grid fill rows={["auto", "1fr", chartHeight + 18 + "px", "1fr"]} pad="small">
                <Box alignContent="between">
                    <Text size={getRelativeBodySize("small", screenSize)} weight="bold" margin={{ bottom: "small" }}>
                        {header}
                    </Text>
                </Box>
                <Box>{para1}</Box>
                <Box>
                    <ResponsiveContainer>
                        <AreaChart
                            height={chartHeight}
                            data={chartData.data}
                            margin={{ left: 30, right: 30, bottom: 20, top: 10 }}
                        >
                            <XAxis
                                dataKey="year"
                                ticks={[chartData.data[0].year, chartData.data[chartData.data.length - 1].year]}
                                fontSize="14px"
                            />
                            <YAxis
                                domain={[Math.max(0, chartData.stats.min - yOffset), chartData.stats.max + yOffset]}
                                hide
                            />
                            <Area
                                isAnimationActive={animate}
                                dataKey="ma_10"
                                stroke={chartData.color}
                                fill={chartData.color}
                                fillOpacity={0.7}
                                strokeWidth={3}
                                dot={false}
                                activeDot={false}
                                legendType="none"
                            ></Area>
                            <ReferenceDot
                                ifOverflow="extendDomain"
                                r={0}
                                x={chartData.data[0].year}
                                y={startDotY}
                                label={(pt) => {
                                    return (
                                        <g fill={chartData.color} stroke={chartData.color}>
                                            <text
                                                x={pt.viewBox.x}
                                                y={pt.viewBox.y - 25}
                                                fill={chartData.color}
                                                fontSize="14px"
                                                textAnchor="start"
                                            >
                                                {chartData.stats.startValue}
                                                {chartData.units}
                                            </text>
                                        </g>
                                    );
                                }}
                            ></ReferenceDot>
                            <ReferenceDot
                                ifOverflow="extendDomain"
                                r={0}
                                x={chartData.data[chartData.data.length - 1].year}
                                y={endDotY}
                                label={(pt) => {
                                    return (
                                        <g>
                                            <text
                                                x={pt.viewBox.x - 5}
                                                y={pt.viewBox.y - 25}
                                                fill={chartData.color}
                                                stroke={chartData.color}
                                                fontSize="14px"
                                                textAnchor="end"
                                            >
                                                {chartData.stats.endValue}
                                                {chartData.units}
                                            </text>
                                            <text
                                                x={pt.viewBox.x - 5}
                                                y={pt.viewBox.y - 10}
                                                fill="#444444"
                                                stroke="#444444"
                                                fontSize="12px"
                                                textAnchor="end"
                                            >
                                                {chartData.stats.changeDir === "pos" ? "+" : "-"}
                                                {chartData.stats.pctChange}
                                            </text>
                                        </g>
                                    );
                                }}
                            ></ReferenceDot>
                        </AreaChart>
                    </ResponsiveContainer>
                </Box>
                <Box>{para2}</Box>
            </Grid>
            // </Box>
        );
    };

    const CustomRadioButtonGroup = ({ options, value, onChange, primaryColor, name }) => {
        return (
            <RadioButtonGroup
                name={name}
                options={options}
                direction="row"
                gap="none"
                value={value}
                onChange={onChange}
            >
                {(option, { checked, hover }) => {
                    const background = "none";
                    const borderColor = checked ? primaryColor : "rgba(0,0,0,0)";
                    return (
                        <Box
                            background={background}
                            border={[
                                { side: "top", color: "rgba(0,0,0,0)", size: "medium", style: "solid" },
                                { side: "bottom", color: borderColor, size: "medium", style: "solid" },
                            ]}
                            pad={{ vertical: "none", horizontal: "xsmall" }}
                        >
                            <Text size={getRelativeBodySize("small", screenSize)}>{option.label}</Text>
                        </Box>
                    );
                }}
            </RadioButtonGroup>
        );
    };

    let shortName = selectedLocationMetadata.label
    if (selectedLocationMetadata.geo_level !== 'state'){
        shortName = shortName.substring(0, selectedLocationMetadata.label.length - 4);
    }
    
    const StatementContent = () => {
        const tempStatement =
            allChartData.hotDays.stats.startValue === "0"
                ? allChartData.hotDays.stats.valueChange +
                  " " +
                  (allChartData.hotDays.stats.changeDir === "pos" ? "more" : "fewer") +
                  " extremely hot days"
                : allChartData.hotDays.stats.pctChange +
                  " " +
                  (allChartData.hotDays.stats.changeDir === "pos" ? "increase" : "decrease") +
                  " in extremely hot days";
        const rainStatement =
            allChartData.heavyRain.stats.startValue === "0"
                ? allChartData.heavyRain.stats.valueChange +
                  " " +
                  (allChartData.heavyRain.stats.changeDir === "pos" ? "more" : "fewer") +
                  " days with heavy precipitation"
                : allChartData.heavyRain.stats.pctChange +
                  " " +
                  (allChartData.heavyRain.stats.changeDir === "pos" ? "increase" : "decrease") +
                  " in days with heavy precipitation";
        return (
            <Text size={getRelativeBodySize("large", screenSize)}>
                {shortName} is expected to experience a{" "}
                <Text size={getRelativeBodySize("large", screenSize)} color="heRed" weight="bold">
                    {tempStatement}
                </Text>{" "}
                and a{" "}
                <Text size={getRelativeBodySize("large", screenSize)} color="heDarkBlue" weight="bold">
                    {rainStatement}
                </Text>{" "}
                within <b>{state.yearSpan} years.</b>
            </Text>
        );
    };

    const HeaderContent = () => {
        return (
            <Box direction="row" justify="between" align="center" gap="small" fill="horizontal">
                <Heading level="2" size={getRelativeHeadingSize("large", screenSize)} margin="0 0 0 0" align="center">
                    Explore climate projections
                </Heading>
                <Box direction="row" justify="end">
                    <Box
                        pad={{ left: "xsmall", right: "medium", vertical: "xsmall" }}
                        fill="vertical"
                        border={{ side: "right", color: "dark-5" }}
                        align="baseline"
                    >
                        <Text size={getRelativeBodySize("medium", screenSize)} weight="bold">
                            Select time range:
                        </Text>
                        <DropButton
                            label={
                                <Box
                                    direction="row"
                                    gap="xsmall"
                                    border={{ style: "solid", size: "medium", side: "bottom", color: "text" }}
                                    align="center"
                                >
                                    <FontAwesomeIcon icon={faChevronDown} size="1x" />
                                    <Text size={getRelativeBodySize("small", screenSize)}>{state.yearSpan} Years</Text>
                                </Box>
                            }
                            alignSelf="center"
                            plain
                            margin={{ top: "xxsmall" }}
                            dropAlign={{ top: "bottom", right: "right" }}
                            dropContent={
                                <YearRangeSelector
                                    startValue={state.yearSpan}
                                    onChangeComplete={(value) =>
                                        set_state({ ...state, yearSpan: value, chartsToAnimate: "all" })
                                    }
                                />
                            }
                        />
                    </Box>
                    <Box direction="column" align="center" pad={{ left: "medium", right: "none", vertical: "xsmall" }}>
                        <Text size={getRelativeBodySize("medium", screenSize)} weight="bold">
                            Select an emissions scenario:
                        </Text>
                        <CustomRadioButtonGroup
                            name="radioBtnGroup_emissions"
                            primaryColor="text"
                            options={[
                                { value: "high", label: "Higher Emissions (RCP8.5)" },
                                { value: "low", label: "Lower Emissions (RCP4.5)" },
                            ]}
                            value={state.emissionsScenario}
                            onChange={({ target: { value } }) =>
                                set_state({ ...state, emissionsScenario: value, chartsToAnimate: "all" })
                            }
                        />
                    </Box>
                </Box>
            </Box>
        );
    };

    const HeatContent = () => {
        return (
            <>
                <Box
                    pad={{ vertical: "xsmall", horizontal: "small" }}
                    background="heRed-2"
                    direction="row"
                    gap="xsmall"
                    align="center"
                >
                    <FontAwesomeIcon icon={faTemperatureHot} size="lg" />
                    <Heading size={getRelativeHeadingSize("xlarge", screenSize)} level="2" margin="none">
                        HEAT
                    </Heading>
                </Box>
                <Grid
                    fill
                    columns={screenSize === "mobile" ? ["1fr"] : ["1/2", "1/2"]}
                    gap={screenSize === "mobile" ? "medium" : "none"}
                >
                    <StatsBlock
                        animate={["all", "daysOverTemp"].includes(state.chartsToAnimate)}
                        header={
                            <Box direction="row" justify="start" align="center" gap="xsmall">
                                <Heading
                                    size={getRelativeBodySize("large", screenSize)}
                                    color="heRed"
                                    level="3"
                                    margin="none"
                                >
                                    Days per year above:
                                </Heading>
                                <Box>
                                    <CustomRadioButtonGroup
                                        name="radioBtnGroup_heat"
                                        primaryColor="heRed"
                                        options={[
                                            { value: "90", label: <Text>90&#176;F</Text> },
                                            { value: "95", label: <Text>95&#176;F</Text> },
                                            { value: "100", label: <Text>100&#176;F</Text> },
                                        ]}
                                        value={state.daysOverTemp}
                                        onChange={({ target: { value } }) =>
                                            set_state({
                                                ...state,
                                                daysOverTemp: value,
                                                chartsToAnimate: "daysOverTemp",
                                            })
                                        }
                                    />
                                </Box>
                            </Box>
                        }
                        para1={
                            <Text size={getRelativeBodySize("medium", screenSize)}>
                                By {endYear}, {shortName} is expected to experience{" "}
                                <b>
                                    {allChartData.hotDays.stats.valueChange}{" "}
                                    {allChartData.hotDays.stats.changeDir === "pos" ? "more" : "fewer"} days
                                </b>{" "}
                                that reach above {state.daysOverTemp}
                                {<Text>&#176;</Text>}F (from {allChartData.hotDays.stats.startValue} days to{" "}
                                {allChartData.hotDays.stats.endValue} days per year).
                            </Text>
                        }
                        para2={
                            <Text size={getRelativeBodySize("xsmall", screenSize)}>
                                Extremely hot days are the leading cause of weather-related fatalities in the U.S. and
                                contribute to economic stress as the need for (and cost of) air conditioning rises.
                            </Text>
                        }
                        chartData={allChartData.hotDays}
                    />
                    <StatsBlock
                        animate={state.chartsToAnimate === "all"}
                        header={
                            <Heading
                                size={getRelativeBodySize("large", screenSize)}
                                color="heRed"
                                level="3"
                                margin="none"
                            >
                                Average annual temperature
                            </Heading>
                        }
                        para1={
                            <Text size={getRelativeBodySize("medium", screenSize)}>
                                By {endYear}, {shortName} is expected to have a{" "}
                                <b>
                                    {allChartData.aveTemp.stats.valueChange}&#176;F{" "}
                                    {allChartData.aveTemp.stats.changeDir === "pos" ? "increase" : "decrease"}
                                </b>{" "}
                                (from {allChartData.aveTemp.stats.startValue}&#176;F to{" "}
                                {allChartData.aveTemp.stats.endValue}&#176;F) in average annual temperatures.
                            </Text>
                        }
                        para2={
                            <Text size={getRelativeBodySize("xsmall", screenSize)}>
                                Increasing annual temperatures contribute to droughts, longer and more catastrophic
                                wildfire seasons, and warmer oceans that fuel hurricanes and offshore storms.
                            </Text>
                        }
                        chartData={allChartData.aveTemp}
                    />
                </Grid>
            </>
        );
    };

    const RainfallContent = () => {
        return (
            <>
                <Box
                    pad={{ vertical: "xsmall", horizontal: "small" }}
                    background="heBlue-2"
                    direction="row"
                    gap="xsmall"
                    align="center"
                >
                    <FontAwesomeIcon icon={faRaindrops} size="lg" />
                    <Heading size={getRelativeHeadingSize("xlarge", screenSize)} level="2" margin="none">
                        PRECIPITATION
                    </Heading>
                </Box>
                <Grid fill columns={screenSize === "mobile" ? ["1fr"] : ["1/2", "1/2"]}>
                    <StatsBlock
                        animate={["all", "daysOverPcpn"].includes(state.chartsToAnimate)}
                        header={
                            <Box direction="row" justify="start" align="center" gap="xxsmall">
                                <Heading
                                    size={getRelativeBodySize("large", screenSize)}
                                    color="heDarkBlue"
                                    level="3"
                                    margin="none"
                                >
                                    Days per year with precip. above:
                                </Heading>
                                <Box>
                                    <CustomRadioButtonGroup
                                        name="radioBtnGroup_rainfall"
                                        primaryColor="heDarkBlue"
                                        options={[
                                            { value: "1", label: <Text>1&Prime;</Text> },
                                            { value: "2", label: <Text>2&Prime;</Text> },
                                            { value: "4", label: <Text>4&Prime;</Text> },
                                        ]}
                                        value={state.daysOverPcpn}
                                        onChange={({ target: { value } }) =>
                                            set_state({
                                                ...state,
                                                daysOverPcpn: value,
                                                chartsToAnimate: "daysOverPcpn",
                                            })
                                        }
                                    />
                                </Box>
                            </Box>
                        }
                        para1={
                            <Text size={getRelativeBodySize("medium", screenSize)}>
                                By {endYear}, {shortName} is expected to experience{" "}
                                <b>
                                    {allChartData.heavyRain.stats.valueChange}{" "}
                                    {allChartData.heavyRain.stats.changeDir === "pos" ? "more" : "fewer"} days
                                </b>{" "}
                                of heavy precipitation per year (from {allChartData.heavyRain.stats.startValue} days to{" "}
                                {allChartData.heavyRain.stats.endValue} days per year).
                            </Text>
                        }
                        para2={
                            <Text size={getRelativeBodySize("xsmall", screenSize)}>
                                Heavy precipitation leads to both riverine flooding and flash floods as the ground fails
                                to absorb the high volume of precipitation that falls in a short period.
                            </Text>
                        }
                        chartData={allChartData.heavyRain}
                    />

                    <StatsBlock
                        animate={state.chartsToAnimate === "all"}
                        header={
                            <Heading
                                size={getRelativeBodySize("large", screenSize)}
                                color="heDarkBlue"
                                level="3"
                                margin="none"
                            >
                                Average annual precipitation
                            </Heading>
                        }
                        para1={
                            <Text size={getRelativeBodySize("medium", screenSize)}>
                                By {endYear}, {shortName} is expected to have a{" "}
                                <b>
                                    {allChartData.aveRain.stats.valueChange}&Prime;{" "}
                                    {allChartData.aveRain.stats.changeDir === "pos" ? "increase" : "decrease"}
                                </b>{" "}
                                (from {allChartData.aveRain.stats.startValue}&Prime; to{" "}
                                {allChartData.aveRain.stats.endValue}&Prime;) in average annual precipitation.
                            </Text>
                        }
                        para2={
                            <Text size={getRelativeBodySize("xsmall", screenSize)}>
                                Increasing annual precipitation contributes to sustained flooding. For example, in 2019
                                areas along the Mississippi remained above flood stage for at least three months.
                            </Text>
                        }
                        chartData={allChartData.aveRain}
                    />
                </Grid>
            </>
        );
    };

    return (
        <>
            <ClimateWarningForStates />
            <Grid fill={screenSize === "mobile" ? "horizontal" : true} rows={["auto", "auto", "1fr"]}>
                <Box pad="small" background="light-4">
                    <StatementContent />
                </Box>
                <Box background="dark-2" direction="row" justify="between" align="center" pad={{ horizontal: "small" }}>
                    <HeaderContent />
                </Box>
                <Grid columns={screenSize !== "desktop" ? ["1fr"] : ["1fr", "1fr"]}>
                    <Box background="heRed-3">
                        <HeatContent />
                    </Box>
                    <Box background="heBlue-3">
                        <RainfallContent />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default ClimateTab;
