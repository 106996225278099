import React, { useContext, useEffect, useState } from "react";
import mapTabContext from "../../../context/mapTabContext";
import Loader from '../../Loader';
import { useViewport, getRelativeHeadingSize } from "@headwaters-economics/web-shared";
import { Box, Button, CheckBoxGroup, Layer, ThemeContext, Heading, Stack } from "grommet";
import { Close as CloseIcon } from "grommet-icons";
import parameterLU from "../../../assets/data/parameterLU.json";
import styled from "styled-components";


const StyledButton = styled(Button)`
    border: solid 2px;
    border-radius: 3px;
    padding: 0.25em 0.5em;
    font-weight: 700;
    text-align: center;
    color: #3d7199;
    font-size: 0.75em;
    &:hover {
        border: solid 2px #3d7199;
        background: #3d7199;
        color: #f2f2f2;
    }
    &:focus {
        border: solid 2px #3d7199;
    }
`;

const customLayerTheme = {
    layer: {
        zIndex: 30,
        container: {
            zIndex: 35,
        },
    },
};

function FilterSelector() {
    const { filters, setFilters } = useContext(mapTabContext);
    const [isModalVisible, set_isModalVisible] = useState(false);
    const [filtersInitializing, set_filtersInitializing] = useState([]);
    const { screenSize } = useViewport();

    useEffect(() => {
        const equityFilters = filters.filter((f) => f.type === "equity");
        const visibleEquityFilters = equityFilters.filter((f) => f.visible).map((f) => f.key);
        const uninitializedFilters = filters.filter(f=>visibleEquityFilters.includes(f.key) && !f.initialized);
        if (uninitializedFilters.length === 0){
            set_filtersInitializing([]);
        } 
    }, [filters])

    const equityFilters = filters.filter((f) => f.type === "equity");
    const visibleEquityFilters = equityFilters.filter((f) => f.visible).map((f) => f.key);

    return (
        <ThemeContext.Extend value={customLayerTheme}>
            <StyledButton
                size="small"
                plain
                focusIndicator={false}
                onClick={() => {
                    set_isModalVisible(true);
                }}
            >
                SEE MORE
            </StyledButton>
            {isModalVisible && (
                <Layer onEsc={() => set_isModalVisible(false)}>
                    <Box flex={false} direction="row" justify="between" align="center" background="dark-2" pad={"small"}>
                        <Heading size={getRelativeHeadingSize("large", screenSize)}>Add Characteristics</Heading>
                        <Button plain icon={<CloseIcon />} onClick={() => set_isModalVisible(false)} />
                    </Box>
                    <Box>
                        <Stack fill>
                            <Box pad="small">
                                <CheckBoxGroup
                                    labelKey="label"
                                    valueKey="id"
                                    value={visibleEquityFilters}
                                    options={equityFilters.map((f) => ({
                                        key: f.key,
                                        id: f.key,
                                        label: <>{parameterLU[f.key].label}</>,
                                    }))}
                                    onChange={({ value: newValues }) => {
                                        const uninitializedFilters = filters.filter(f=>newValues.includes(f.key) && !f.initialized);
                                        set_filtersInitializing(uninitializedFilters);
                                        setFilters(
                                            filters.map((f) => {
                                                return {
                                                    ...f,
                                                    visible: f.type === "exposure" ? f.visible : newValues.includes(f.key),
                                                };
                                            })
                                        );
                                    }}
                                />
                            </Box>
                            {filtersInitializing.length > 0 && <Loader background={{ color: "light-1", opacity: "medium" }} />}
                        </Stack>
                    </Box>
                </Layer>
            )}
        </ThemeContext.Extend>
    );
}

export default FilterSelector;
