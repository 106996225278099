import React from "react";
import styled from "styled-components";
import { Button } from "grommet";
import { colors } from "@headwaters-economics/web-shared";

const StyledDownloadButton = styled(Button)`
  margin: 0px;
  border-radius: 0px;
  background-color: ${colors.heDarkBlue};
  padding: 0.5em 1em;
  &:hover {
    background-color: ${colors["heBlue-2"]};
  }
`;

const StyledDownloadButtonDisabled = styled(StyledDownloadButton)`
  background-color: grey;
  &:hover {
    background-color: grey;
  }
  cursor: not-allowed;
`;

const DownloadButton = ({ label, onClick, style, disabled, disabledTip }) => {
  if (disabled && disabled === true) {
    return (
      <StyledDownloadButtonDisabled
        label={label}
        onClick={()=>{}}
        sytle={style}
        tip={disabledTip}
      />
    );
  }
  return (
    <StyledDownloadButton
      label={label}
      onClick={onClick}
      sytle={style}
    />
  );
};
export default DownloadButton;
