import React, { useContext } from "react";
import mapTabContext from "../../../context/mapTabContext";
import appContext from "../../../context/appContext";
import Loader from "../../Loader";
import { Box, Heading, Text } from "grommet";
import { useViewport, getRelativeBodySize, getRelativeHeadingSize } from "@headwaters-economics/web-shared";

import FilterSlider from "./FilterSlider";
import CriteriaSetter from "./CriteriaSetter";
import FilterSelector from "./FilterSelector";

function MapSidebar() {
    const { selectedLocationMetadata } = useContext(appContext);
    const { filters, epsRanks, isFilteringDisabled } = useContext(mapTabContext);
    const { screenSize } = useViewport();
    // const currentPlaceData = placeData[selectedLocation.id];

    if (!filters || filters.length === 0) return <Loader />;

    const equityFilters = filters.filter((f) => f.type === "equity" && f.visible && f.initialized);
    let exposureFilters = filters.filter((f) => f.type === "exposure" && f.visible && f.initialized);

    if (equityFilters.length === 0 || exposureFilters.length === 0) return <Loader />;
    // exposureFilters = exposureFilters.filter((f) => !f.incomplete); // exclude exposure filters that are missing data for one or more tracts

    const isTopThreeEconFiltersShown =
        equityFilters.length === 3 &&
        equityFilters.every((equityFilter) => epsRanks.findIndex((rank) => rank.var === equityFilter.key) < 3);

    return (
        <Box overflow={{ vertical: "auto" }}>
            <Box flex="grow">
                <Box pad={{ vertical: "xsmall", horizontal: "small" }} background="dark-2">
                    <Heading size={getRelativeHeadingSize("medium", screenSize)} margin={{ vertical: "none" }} level="2">
                        Find at-risk neighborhoods
                    </Heading>
                </Box>
                <Box background="heBlue-2" direction="row" align="baseline" justify="between">
                    <Heading
                        size={getRelativeHeadingSize("small", screenSize)}
                        margin={{ horizontal: "small", vertical: "xsmall" }}
                        level="3"
                    >
                        People
                    </Heading>
                </Box>
                <Box pad={{ horizontal: "small", vertical: "xsmall" }}>
                    {isFilteringDisabled && selectedLocationMetadata && (
                        <Text size="xsmall" weight="bold">
                            Filters disabled for{" "}
                            {selectedLocationMetadata.label.includes("County") ? "counties" : "communities"} with one
                            tract.
                        </Text>
                    )}
                    <CriteriaSetter />
                    {/* {epsRanks && filters
                            ? epsRanks
                                  .filter((parameterRank) => filters.map((visibleParam) => visibleParam.key).includes(parameterRank.var))
                                  .map((item) => <FilterSlider key={item.var} parameterKey={item.var} />)
                            : null} */}
                    {equityFilters.map((filter) => {
                        return <FilterSlider key={filter.key} parameterKey={filter.key} />;
                    })}
                    {isTopThreeEconFiltersShown && (
                        <Box pad={{ bottom: "xxsmall", top: "small" }}>
                            <Text size={getRelativeBodySize("xsmall", screenSize)}>
                                <i>Top three characteristics shown.</i>
                            </Text>
                        </Box>
                    )}
                    <FilterSelector />
                </Box>
                {exposureFilters.length > 0 && (
                    <>
                        <Box background="heBlue-2">
                            <Heading
                                size={getRelativeHeadingSize("small", screenSize)}
                                margin={{ horizontal: "small", vertical: "xsmall" }}
                                level="3"
                            >
                                Climate Exposure
                            </Heading>
                        </Box>
                        <Box pad={{ horizontal: "small", top: "xsmall" }}>
                            {exposureFilters.map((f) => {
                                return <FilterSlider key={f.key} parameterKey={f.key} />;
                            })}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
}

export default MapSidebar;
